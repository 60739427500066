import React from "react";

const CompanyDashboardSetting = () => {
  return (
    <div>
      <h1>This is Setting</h1>
    </div>
  );
};

export default CompanyDashboardSetting;
